






















import {Component, Prop, Vue} from 'vue-property-decorator'
import {WebBanner} from '../data-class/data-class'

@Component({})
export default class Banner extends Vue {
    @Prop() data: WebBanner
    @Prop({default: true}) inCarousel: boolean

    get color() {
        if (this.data.bkg_color)
            return 'background-color: ' + this.data.bkg_color
        return ''
    }

    get image() {
        return 'background:url(\'' + this.data.image_url + '\') no-repeat;' + this.color
    }

    get size() {
        if (this.data.size === 'cover') {
            return 'bkg-cover'
        } else {
            return 'bkg-contain'
        }
    }

    get position() {
        if (this.data.position === 'top') {
            return 'bkg-top'
        } else if (this.data.position === 'center') {
            return 'bkg-center'
        } else {
            return 'bkg-bottom'
        }
    }

    get isOwnContent() {
        return this.data.link.startsWith('/')
    }
}
