




















import {Component, Prop, Vue} from 'vue-property-decorator'
import {WebBanner} from '../data-class/data-class'
import Banner from './banner.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// import 'swiper/swiper-bundle.css'

Vue.use(VueAwesomeSwiper)

@Component({
    components: {Banner}
})
export default class BannerPreview extends Vue {
    @Prop() banners: WebBanner[]

    bannerOptions = {
        slidesPerView: 1,
        slidesPerGroup: 1,
        autoplay: false,
        navigation: {
            prevEl: '.owl-prev',
            nextEl: '.owl-next'
        },
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    }

    isMobile() {
        return window? window.innerWidth < 992 : false
    }

    get smallScreen() {
        return window? window.innerWidth < 768 : false
    }
}
