




































































































import ElTableDraggable from '~/components/sortable-el-table.vue'
import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import BannerPreview from '~/components/banner/banner-preview.vue'
import {AppBanner} from '~/components/data-class/data-class'
import {BannerPermission} from '~/utils/permissions'
import NumberDatePicker from '~/components/banner/number-date-picker.vue'

@Component({
    components: {
        BannerPreview,
        ElTableDraggable,
        NumberDatePicker
    },
    metaInfo() {
        return {
            title: 'App Banner'
        }
    }
})
export default class AppBanners extends Vue {
    static permission = BannerPermission.AppBanner

    banners: AppBanner[] = []

    dialogDeleteBannerVisible = false

    deleteBannerIndex: number = -1

    selectedColor = '#43d0c0'

    dialogUploadImageVisible = false
    uploadBanner: AppBanner = new AppBanner()
    fileList = []

    created() {
        this.updateBanner()
    }

    setBanners(banners) {
        this.deleteBannerIndex = -1
        this.banners = banners
    }

    async updateBanner() {
        const res = await createRequest('/news/banners', 'get').send()
        this.setBanners(res.data.banners)
    }

    showDeleteBanner(index) {
        this.dialogDeleteBannerVisible = true
        this.deleteBannerIndex = index
    }

    async deleteBanner() {
        this.dialogDeleteBannerVisible = false
        const updateBannerList: AppBanner[] = []
        for (let i = 0; i < this.banners.length; i++)
            if (i !== this.deleteBannerIndex)
                updateBannerList.push(this.banners[i])
        const res = await createRequest('/news/banners', 'put', {}, {'banners': updateBannerList}).send()
        this.setBanners(res.data.banners)
    }

    addBanner() {
        this.banners.push(new AppBanner())
        window.scrollTo(0, document.body.scrollHeight)
    }

    startUpload(banner) {
        this.uploadBanner = banner
        this.dialogUploadImageVisible = true
    }

    async save() {
        const res = await createRequest('/news/banners', 'put', {}, {'banners': this.banners}).send()
        this.setBanners(res.data.banners)
    }

    get uploadUrl() {
        return `${process.env.VUE_APP_DOMAIN_API}/file`
    }

    async uploadImage(param) {
        const fileObj = param.file
        const form = new FormData()
        form.append('file', fileObj)

        try {
            const res = await createRequest('/file', 'post', {}, form).send()
            this.uploadBanner.image_url = res.data.file_url
            await this.save()
            this.dialogUploadImageVisible = false
        } catch (e) {
            console.log(e)
            this.$message({
                message: `Upload Failed ${JSON.stringify(e)}`,
                type: 'error'
            })
        }
    }
}
